<template>
  <default-layout>
    <div>
      <vue-page-transition name="fade">
        <router-view></router-view>
      </vue-page-transition>
    </div>
  </default-layout>
</template>

<script>
export default {
  name: 'ProfileLayout'
}
</script>
